/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

:root {
    --bg: #0E1015;
    --mainPanels: #2e3140;
    --mainPanelColor: #1B1D28;
    --primaryBg: #14161E;
    --colorTextMd: #54586C;
    --colorTextLg: #8A8EAB;
    --mainColor: #00CD39;
    --textBtn: #053312;
    --buttonGradient: linear-gradient(to bottom, #2CFF66, #007C23);
    --shadow: #14161E;
    --mainText: #ffff;
}
@font-face {
    font-family: 'icomoon';
    src:  url('../src/assets/fonts/icomoon.eot');
    src:  url('../src/assets/fonts/icomoon.eot') format('embedded-opentype'),
    url('../src/assets/fonts/icomoon.ttf') format('truetype'),
    url('../src/assets/fonts/icomoon.woff') format('woff'),
    url('../src/assets/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    background-color: var(--bg);
}
button:disabled {
    opacity: 0.3;
}
.bombColor {
    background-color: #14161E;
}
.main-height {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 70px 1fr;
    grid-template-rows: 70px 1fr;
    height: 100%;
}
html, body{
    height: 100%;
    font-weight: normal !important;
}
.nav__logo{
    max-width: 200px;
    margin-left: 20px;
}
.main-panel {
    margin-top: 10px;
}
.main-body {
    margin: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 1080px;
}
#fk_loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #14161ee0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 9;
}
#cube {
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
#logo_z {
    -webkit-animation-name: spin2;
    animation-name: spin2;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
#logo_e {
    -webkit-animation-name: spin2;
    animation-name: spin2;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes spin2 {
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes spin2 {
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.mainBg {
    background-color: var(--bg);
}
/* NAV */
.nav {
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 99999999;
}
.bords-l {
    border-left: 1px solid var(--mainPanels);
    height: 66px;
    padding: 10px;
}
.bords-r {
    border-right: 1px solid var(--mainPanels);
    height: 66px;
    padding: 10px;
}
.titleMain {
    font-size: 20px;
}
.vkColor {
    background: #4A76A8 !important;
    color: var(--mainText) !important;
}
.fbColor {
    background: #3B589E !important;
    color: var(--mainText) !important;

}
.mainColor {
    background-color: var(--primaryBg) !important;
}
.textMain {
    color: var(--mainText);
}
.nav__toggler {
    border: none;
    height: 24px;
    background: none;
}
.balance {
}
.nav__toggler img{
    -webkit-filter: invert(21%) sepia(84%) saturate(2416%) hue-rotate(186deg) brightness(118%) contrast(119%);
    filter: invert(21%) sepia(84%) saturate(2416%) hue-rotate(186deg) brightness(118%) contrast(119%);
}
.color-text-md {
    color: var(--colorTextMd) ;

}
.color-text-lg {
    color: var(--colorTextLg) ;
}
.color-text-sm {
    color: var(--mainPanels);
}
.fs_12 {
    font-size: 12px !important;
    color: var(--colorTextMd) ;
}
.backroom span{
    font-size: 13px;
    font-weight: 700;
    margin-left: 10px;
}
.backroom i {
    font-size: 24px;
}
.balance {
    color: var(--mainColor) ;
    font-weight: 900;
}
.socialBtn {
    border-radius: 100% !important;
    height: 34px;
    width: 34px;
    margin: 20px !important;
    padding: 0 !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.white-grad {

}
.button {
    color: var(--textBtn) ;
    border: 1px solid transparent !important;
    border-radius: 2px ;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    background:
            -webkit-gradient(linear ,left top, left bottom ,from(var(--mainColor)) ,to(var(--mainColor)) ) padding-box, /*this is your grey background*/
            var(--buttonGradient) border-box;
    background:
            -o-linear-gradient(var(--mainColor) ,var(--mainColor) ) padding-box, /*this is your grey background*/
            var(--buttonGradient) border-box;
    background:
            linear-gradient(var(--mainColor) ,var(--mainColor) ) padding-box, /*this is your grey background*/
            var(--buttonGradient) border-box;
    padding: 12px 27px;
    display:inline-block;
    margin: 25px 0;
}
.button-dis {
    opacity: 0.4;
}
.button i {
    margin-right: 6px;
    font-size: 20px;
}
.close {
    padding: 0.5rem 1rem !important;
}
.close span {
    font-size: 50px;
    font-weight: 100;
    text-shadow: none;
    color: var(--colorTextMd) ;
}
.user_profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.nav-item:first-child {
    border-radius: 3px 0 0 3px !important;
}
.nav-item:last-child {
    border-radius: 0 3px 3px 0 !important;
}
.nav-item:focus {
    outline: none !important;
}
.nav-tabs {
    border-bottom: 1px solid var(--mainPanels) !important;
}
.nav-tabs .nav-link:hover {
    border-color: transparent !important;
}
.nav-link.active {
    border-radius: 3px !important;
    background-color: var(--mainPanels) !important;
    border-color: transparent !important;

}
.nav-link.active strong {
    color: white !important;
}
.pay__btn {
    background-color: var(--mainPanelColor) ;
    width: 140px;
}
.pay__selected {
    background-color: rgb(239, 239, 239)
}
.payment__input {
    border: none;
    background: none;
    height: 42px;
    color: var(--mainText);
    padding: 12px;

}
.payment__input::-webkit-input-placeholder {
    color: var(--colorTextLg) ;
}
.payment__input::-moz-placeholder {
    color: var(--colorTextLg) ;
}
.payment__input:-ms-input-placeholder {
    color: var(--colorTextLg) ;
}
.payment__input::-ms-input-placeholder {
    color: var(--colorTextLg) ;
}
.payment__input::placeholder {
    color: var(--colorTextLg) ;
}
.payment__input:focus {
    outline: none !important;
}
.payment__input button {
    margin: 5px;
}
/* END NAV */

/* CHAT */

.user_profile .avatar {
    border-radius: 100%;
    min-width: 42px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
}
.user_profile .avatar img {
    width: 42px;
    height: 42px;
}
.chat .avatar {
    border-radius: 100%;
    min-width: 36px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    margin: 16px 0px 0px 16px;
}
.game__stats-user .avatar {
    border-radius: 100%;
    min-width: 36px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    margin: 16px 0px 0px 16px;
}
.datatable__history .avatar {
    border-radius: 100%;
    min-width: 36px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    margin: 16px 0px 0px 16px;
}
.datatable__top .avatar {
    border-radius: 100%;
    min-width: 36px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    margin: 16px 0px 0px 16px;
}

.avatar__img, .bet__avatar,.avatar {
    width: 100%;
    display: none !important;

}
.chat__message-text {
    margin: 12px;
}
.chat__message-container {
    background: var(--mainPanelColor) ;
    margin: 11px;
}
.chat__message-container {
    border-radius: 10px;
    padding: 10px;
    position: relative;
    color: #fff;
}
.chat__message-container:before {
    content: "";
    position: absolute;
    height: 43px;
    width: 52px;
    background: transparent;
    bottom: 2%;
    border-bottom: 12px solid var(--mainPanelColor) ;
    border-radius: 100%;
    left: -30px;
    -webkit-transform: rotate(-82deg);
    -ms-transform: rotate(-82deg);
    transform: rotate(-82deg);
}

.chat__message-username {
    margin: 12px;
}
.chat {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    max-width: 350px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}
.mainPanelColor {
    background-color: var(--mainPanelColor)  !important;
}
.chat__header {
    z-index: 200;
    position: relative;
    overflow: hidden;
    height: 53px;
    max-width: 430px;
}
.chat__title {
    padding: 13px;
    margin-right: 28px;
}
.chat__lang {
    padding: 14px;
}
.chat__lang:hover {
    background: var(--mainPanels);
}
.chat__close {
    border: 0;
    background: none;
    margin-right: 10px;
}
.chat__circle {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 100%;
}
.connected {
    background-color: var(--mainColor)  !important;
}
.wait {
    background-color: #FFC10E !important;
}
.fs_16 {
    font-size: 16px !important;
}
.tippy-box[data-theme~='tomato'] {
    background-color: #ff3547;
    color: #6A0F10;
    padding: 10px;
}
.tippy-arrow {
    color: #ff3547 !important;
}
.disconnected {
    background: #ff3547;
}
.color__red {
    color: #ff3547 !important;
}
.color__yellow {
    color: #FFC10E !important;
}
.chat__container {
    margin-top: 0;
    background: var(--primaryBg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.chat__messages {
    height: 500px;
    overflow: auto;
    -webkit-box-flex:100px;
    -ms-flex:100px;
    flex:100px;
    overflow-x: hidden !important;
}
.emoji-mart-preview {
    display: none;
}
.chat__form {
    background: var(--mainPanelColor) ;
    margin: 16px;
    border-radius: 5px;
}
.chat__input {
    border: none;
    background: none;
    height: 42px;
    color: var(--mainText);
    padding: 12px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.chat__button {
    border: none;
    background: none;
}
.emoji-picker-react .emoji-group:before {
    background: transparent !important;
}
.emoji-picker-react .emoji-search {
    background: var(--mainPanelColor) ;
    border: none !important;
}
.emoji-picker-react .emoji-categories {
    background: var(--mainPanels) !important;
}
.top-page {
    overflow-x: hidden !important;
}
.table-scr {
    overflow: auto !important;
}
.authPage {
    background-image: url('assets/bgauth.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
}
.table-responsive>div {
    background-color: var(--primaryBg) !important;
}
aside.emoji-picker-react {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    width: 100% !important;
}
/* END CHAT */
/* SIDEBAR */
.sidebar {
    width: 75px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 99999;
    padding-top: 70px;
    position: fixed;
    top: 0;
}
.sidebar__item {
    padding: 10px 0px 10px 0px;
    text-align: center;
    display: block;
}
.sidebar__item-disable {
    padding: 18px;
    text-align: center;
}
.sidebar__item:hover {
    background: var(--mainPanels);
    border-right: 1px solid var(--mainColor) ;
}
.sidebar__item strong {
    font-size: 12px;
    color: var(--colorTextMd) ;
}
.sidebar__icon {
    font-size: 20px;
}
.sidebar__item:hover .sidebar__icon {
    color: var(--mainColor) ;
}
.sidebar__icon-active {
    color: var(--mainColor) ;
}
.sidebar-container {
    min-width: 63px;
}
.sidebar__item-active {
    background: var(--mainPanels);
    border-right:1px solid var(--mainColor) ;
}
/* END SIDEBAR */

/* ROOMS */
.rooms {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}
.room__active {
    background: var(--mainPanels) !important;
}
.room__state-active {
    background: var(--mainColor)  !important;
    border-radius: 0 3px 3px 0;
    padding: 10px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.room__inactive {
    padding: 13px;
}
.room__state {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    height: 100%;
}
.room__state-active *{
    color: var(--textBtn) !important;
}
.rooms__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}
.rooms__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 0 15px 15px 15px;
}
.rooms__buttons {
    font-size: 13px;
}
.room-btn {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 0;
    border-radius: 3px;
    padding: 0 0 0 17px;
    margin: 15px;
    height: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.room__users {
    margin-right: 10px;
}
.room__settings {
    font-size: 18px;
}
.room__settings span {
    margin-left: 10px;
}
.room-btn__active {
    background: var(--mainPanels);
}
.room-btn__active-wrapper {
    background: var(--mainColor) ;

}
.rooms__chance span{
    color: var(--mainColor) ;
}
/* END ROOMS */

/*LIVEDROP*/
.livedrop__card:first-child {
    margin: 0;
}
.livedrop__card {
    min-width: 156px;
    border-radius: 2px 2px 0 0;
    -webkit-box-shadow: 0 -142px 48px -50px var(--primaryBg) inset, inset 0 -20px 38px 13px var(--shadow);
    box-shadow: 0 -142px 48px -50px var(--primaryBg) inset, inset 0 -20px 38px 13px var(--shadow);
    height: 91px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.livedrop__card-balance {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 9px;
}
.livedrop__card-winner {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}
.livedrop__card-winner span {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
}
.livedrop__card-winner img{
    width: 24px;
    height: 24px;
    border-radius: 100%;
}
/*END LIVEDROP*/
.dropdown-toggle::after {
    color: var(--colorTextMd)  !important;
}
/*GAME*/
.game__roulette {
    overflow: hidden;
    border-radius: 2px;
    margin: 10px;
}

.roulette__container {
    border-radius: 2px;
    height: 60px;
    width: 100%;
    background-color: var(--bg);
    overflow: hidden;
    position: relative;
}
.roulette {
    height: 100%;
    will-change: transform;
}
.roulette__users {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    height: 100%;
}
.user {
    height: 50px;
    float: left;
}
.check {
    font-size: 50px;
    color: transparent;
    display: inline;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    z-index: 50;
    font-weight: 100;
}
.app-container {
    display: -ms-grid !important;
    display: grid !important;
    -webkit-box-pack: center;
    overflow: hidden;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-columns: 1fr minmax(300px, 1101px) 1fr;
    grid-template-columns: 1fr minmax(300px, 1101px) 1fr;
    grid-template-areas: '... main ...';
}
.roulette {
    left: 0;
    position: relative;
}
.roulette__animate {
    -webkit-transition: all 7s ease-in-out;
    -o-transition: all 7s ease-in-out;
    transition: all 7s ease-in-out;
    -webkit-transition-timing-function: cubic-bezier(0.01,0.51, 0.3, 1.00);
    -o-transition-timing-function: cubic-bezier(0.01,0.51, 0.3, 1.00);
    transition-timing-function: cubic-bezier(0.01,0.51, 0.3, 1.00);
}
.rooms__top button:focus {
    outline: none;
}
.animate {
    left: -9409px;
}
.roulette__arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 0;
}
.roulette__arrow span {
    border-radius: 10px;
    position: relative;
}
.roulette__arrow span::after {
    content: '';
    position: relative;
    right: 25%;bottom:-16px;
    z-index: 9;
    border: 20px solid transparent;
    border-top: 20px solid var(--primaryBg);
}
.roulette__arrow span::before {
    content: '';
    position: relative;
    left: 25%;bottom:-20px;
    z-index: 9;
    border: 20px solid transparent;
    border-bottom: 20px solid var(--primaryBg);
}
.game__stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 16px;
    font-size: 18px;
}
.roulette__user{
    height: auto;
    position: relative;
}
.roulette__user::after {
    background-image: url("assets/img/backgroundColor.svg") !important;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0.05;
}

.toastAlert {
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    max-width: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

}
.toastBody {
    border-radius: 3px;
    -webkit-box-shadow: -126px 0 100px 19px var(--mainPanelColor)  inset;
    box-shadow: -126px 0 100px 19px var(--mainPanelColor)  inset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;

}
.timer {
    font-size: 23px;
}
.game__bank {
    font-size: 24px;
}
.h24 {
    font-size: 28px;
    color: var(--mainPanels)
}
.my-text-dark {
    color: var(--textBtn)  !important;
}
.toastSuccess {
    border-left: 2px solid var(--mainColor) ;
    background: var(--mainColor)  !important;
}
.toastError {
    border-left: 2px solid #f6003c;
    background: #f6003c !important;
}
.toastBody .toastImg {
    padding: 0 20px;
}
.toastBody p {
    margin: 0;
    padding-right: 10px;
}
.errInput {
    border: 1px rgb(244, 75, 75) solid !important;
    border-radius: 2px;
}
.input-r {
    border-right: 1px solid var(--colorTextMd);
}
.game__bet-form {
    position: relative;
}
.form__label {
    position: absolute;
    bottom: -15px;
    left: 20px;
    color: rgb(240, 73, 73);
    margin-bottom: 0 !important;
}
.game__bet-btn {
    padding: 17px;
    border-radius: 3px;
    border:0;
    background: var(--mainColor)  !important;
}
.game__val-btn {
    padding: 7px;
    border:0;
    border-radius: 3px;
}
.game__val-btn:focus {
    outline: none;
    border: 1px solid var(--mainColor) ;
}
.bet__input {
    border: none;
    background: none;
    height: 42px;
    color: var(--mainText);
    padding: 12px;
    width: 300px;
    margin: 20px;
}
.bet__buttons button {
    margin: 5px;
}
.game__hr {
    background: var(--mainPanels);
    height: 1px;
    width: 100%;
    margin: 0;
    margin: 20px;

}
.bet__color {
    height: auto;
    width: 58px;
    border-radius: 0 3px 3px 0;
    position: relative;
}
.bet__color::after {
    background-image: url("assets/img/backgroundColor.svg") !important;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0.05;
}
.game__bets {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition: max-height 1.5s;
    -o-transition: max-height 1.5s;
    transition: max-height 1.5s;
}
.game__bet {
    border-radius: 3px;
    height: auto;
    margin: 8px;
}
.bet__info {
    padding: 3px;
}
.bet__avatar {
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 100%;
}
.modal-backdrop {
    width: 100% !important;
    height: 100% !important;
}
.faq__support {
    padding: 55px;
    padding-top: 0;
}
.game__bets-overflow {
    position: absolute;
    display: inline-block;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(52.1%, var(--primaryBg)));
    background: -o-linear-gradient(transparent, var(--primaryBg) 52.1%);
    background: linear-gradient(transparent, var(--primaryBg) 52.1%);
    bottom: -1px;
    height: 100px;
    width: 100%;
}
.w-none {
    min-width: 0 !important;
}
.dropdown-item:hover {
    background-color: inherit !important;
}
.game__bets-all {
    max-height: 100% !important;
    -webkit-transition: max-height 1.5s;
    -o-transition: max-height 1.5s;
    transition: max-height 1.5s;
    overflow: auto;

}
.game__bets-show {
    border-bottom: 1px dashed
}
.game {
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
}
.sidenav {
    height: 100vh;
}
/*END GAME*/

/* TOP */
.places-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.places-top__user {

}
.user-top__avatar {
    position: relative;
    width: 170px;
}
.user-top__place{
    width: 100%;
}
.user-top__avatar-img {
    border-radius: 100%;
    position: absolute;
    left: 17px;
    bottom: 17px;
    width: 135px;
    padding: 3px;
}
.user-top__num {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    top: 62px;
    right: 0;
    text-align: center;
    line-height: 38px;
    font-weight: bold;
    font-size: 20px;
}
.border__one {
    border: 3px solid var(--textBtn) ;
}
.border__two {
    border: 3px solid #2b7640;
}
.border__three {
    border: 3px solid #438b58;
}
.one {
    background: #1dff5c;
    color: var(--textBtn) ;
}
.two {
    background: #b6ffca;
    color: #2b7640;
    fill: #41c967;
}
.three {
    background: #d2ffdf;
    color: #438b58;
    fill: #a3f6bb;
}
.datatable__top thead th{
    background: var(--mainPanels) !important;
    color: var(--colorTextLg) ;
    font-weight: bold;
    border-radius: 3px 3px 0 0;
}
.datatable__top thead:last-child{
    display: none;
}
.datatable__top tr:nth-child(even) {
    background: var(--mainPanelColor)  !important;
}

.datatable__top td,th {
    border-top: none !important;
    border-bottom: none !important;
    padding: 10px !important;
}
.place_1 {
    color: var(--mainColor) ;
    -webkit-box-shadow: 101px 0px 123px -153px var(--mainColor)  inset;
    box-shadow: 101px 0px 123px -153px var(--mainColor)  inset;
    border-left: 4px solid var(--mainColor)  !important;
}
.place_2 {
    color: #41c967;
    -webkit-box-shadow: 101px 0px 123px -153px #41c967 inset;
    box-shadow: 101px 0px 123px -153px #41c967 inset;
    border-left: 4px solid #41c967 !important;
}
.place_3 {
    color: #a3f6bb;
    -webkit-box-shadow: 101px 0px 123px -153px #a3f6bb inset;
    box-shadow: 101px 0px 123px -153px #a3f6bb inset;
    border-left: 4px solid #a3f6bb !important;
}
.top__balance {
    font-size: 28px !important;
}
.top__balance img {
    width: 24px;
}
.datatable__top tbody td {
    padding: 15px !important;
    vertical-align: middle;
}
.page-item.active .page-link {
    background-color: var(--mainColor)  !important;
    color: var(--textBtn)  !important;
}
.pagination,.page-item a {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    color: var(--colorTextLg)  !important;
    font-weight: normal;
}
.color__green {
    color: var(--mainColor)  !important;
}
/* END TOP */
/* HISTORY */
.datatable__history thead th {
    background: var(--mainPanels) !important;
    color: var(--colorTextLg) ;
    font-weight: bold;
    border-radius: 3px 3px 0 0;
}

.datatable__history tbody tr:nth-child(4n+1) {
    background: var(--mainPanelColor)  !important;
}
.datatable__history tbody td {
    vertical-align: middle;
    border-top: none;
}
.datatable__history tr:nth-child(odd) td {
    padding: 10px !important;
}
.datatable__history-pay thead th {
    background: transparent !important;
    color: var(--colorTextMd) ;
    font-weight: normal;
    border-radius: 3px 3px 0 0;
}

.datatable__history-pay tbody tr:nth-child(odd) {
    background: var(--mainPanelColor)  !important;
}
.datatable__history-pay tbody td {
    vertical-align: middle;
    border-top: none;
}
.datatable__history-pay tr td {
    padding: 10px !important;
}
.fs_14 {
    font-size: 14px;
}
.row__color {
    height: 100%;
    display: block;
    width: 200px;
    position: absolute;
    top: 0;
}
.history__expand {
    border-bottom: 1px dashed #717589;
    padding: 2px;
    overflow: auto;
    color: var(--colorTextLg)  !important;
}
.exp__active {
    color: var(--mainColor)  !important;
    border-bottom: 1px dashed var(--mainColor) ;

}
.table-responsive {
    width: auto !important;
    margin: 15px;
}
/* END HISTORY */
.react-select__menu,.react-select__control {
    background-color: var(--mainPanelColor)  !important;
    border-color: transparent !important;
}
.react-select__option--is-focused,.react-select__option--is-selected, .react-select__option:hover {
    background-color: var(--mainPanels) !important;
}
.react-select__option--is-focused strong,.react-select__option--is-selected, .react-select__option:hover strong {
    color: var(--mainColor)  !important;
}
.react-select__single-value strong{
    color: var(--mainText) !important;
}
.referral-page {
    background-image: url("assets/bg.png");
    background-size: contain;
    background-attachment: local;
    background-position: center;
    background-repeat: no-repeat;
}
.referral_img {
    margin: 50px;
}
.referral_text {
    margin: 50px;
}
.levels__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-flow: column;
    flex-flow: column;
}
.levels__item {
    background: var(--mainPanelColor) ;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    border-radius: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.levels__item-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.levels__item-progress {
    border-radius: 3px;
    height: 5px;
    background: var(--colorTextMd);
}
@media all and (max-width: 700px) {
    .sidebar-container {
        min-width: 0 !important;
    }

}
@media all and (max-width: 990px) {

    .game__bet {
        height: auto !important;
    }
    .bombs .game__form {
        order: 1
    }
    .bombs .bombPlace {
        zoom: 0.7;
    }
}
@media all and (max-width: 767px){
    html, body, #root {
        height: 100% !important;
    }

    .datatable__top .pagination {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
        color: var(--colorTextLg) ;
    }
    .game__bet {
        height: auto !important;
    }
    .main-panel {
        margin: 10px;
    }
    .bet__avatar {
        display: none;
    }
    body {
        height: auto;
    }
    .app-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .chat {
        max-width: 100%;
    }
    .chat__header {
        max-width: 100%;
    }
    .mobile-width {
        width: 100%;
    }
    .app-container {
        overflow: auto !important;
    }
    .main-body {

    }
    .livedrop {
        display: none !important;
    }
    #root {
        height: 100% !important;
    }
    .col-lg-8 {
        padding: 0 !important;
    }
    .nav__logo {
        width: 114px;
    }
    .nav {
        height: 50px;
    }
    /* SIDEBAR */
    .sidebar__items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .sidenav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-flow: column-reverse;
        flex-flow: column-reverse;
    }
    .chat__form div {
        font-size: 23px;
    }
    .chat__container {
        overflow: auto;
    }
    .chat__messages {
        height: 300px;
        overflow: auto;
        display: block;
    }
    .room__settings {
        font-size: 16px;
    }
    .chat {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 100%;
    }
    .sidebar {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: auto;
        overflow: scroll;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        z-index: 99;
        padding-top: initial !important;
        top: auto;

    }
    .sidebar__item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 18px;
        width: auto;
        text-align: center;
    }
    .sidebar__item-disable {
        padding: 18px;
        text-align: center;
    }
    .sidebar__item:hover {
        background: var(--mainPanels);
        border-top:1px solid var(--mainColor) ;
        border-right: none;
    }
    .sidebar__icon {
        font-size: 20px;
    }
    .sidebar__item:hover .sidebar__icon {
        color: var(--mainColor) ;
    }
    .sidebar__icon-active {
        color: var(--mainColor) ;
    }
    .sidebar__item-active {
        background: var(--mainPanels);
        border-top:1px solid var(--mainColor) ;
    }
    /* END SIDEBAR */

    /* CHAT */
    .chat {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        height: 90vh;
    }
    .panelm {
        height: 60px;
    }
    .chat__container {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .chat__messages {
        height: 100%;
    }
    /* END CHAT */

    /*ROOM*/
    .rooms__top {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .room-btn {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        padding: 0;
        height: auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .room__active {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .room__state-active {
        padding: 0;
        height: auto;
    }
    .room__state-active-mobile * {
        color: var(--textBtn) ;
    }
    .room__state {
        height: auto;
    }
    .button {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin: 15px 0 0 0;
    }
    .room__state-active-mobile {
        background: var(--mainColor)  !important;
        border-radius: 0 0 3px 3px;
        display: block;
        margin: 0;
        padding: 0;
        color: var(--textBtn) ;
    }
    .room__inactive {
        padding: 0;
    }
    .rooms__buttons {
        font-size: 13px;
    }
    .timer {
        font-size: 18px;
    }
    .game__bank {
        font-size: 18px;
    }
    .game__bank svg {
        height: 18px;
    }
    .bet__color {
        width: 15px;
        height: auto;
    }
    .bet__value {
        color: var(--mainText);
    }
    .h24 {
        font-size: 18px;
    }
    .room__wrap {
        width: 100%;
        padding: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .room__wrap:first-child {
        border-bottom: solid 1px var(--mainPanels);
    }
    .rooms__bottom {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .rooms__buttons {
        border-bottom: 1px solid var(--mainPanels);
        width: 100%;
        padding: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .rooms__chance {
        padding: 5px;
    }
    .room__state-active {
        border-radius: 0px 0px 3px 3px;
    }
    /*END ROOM*/
    .mobile__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .mobile__center {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    /*GAME*/
    .bet__buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .bet__input {
        margin-bottom: 5px;
    }
    .game__bet-btn {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-top: 30px;
    }

    .nav .user_profile {
        width: 32px;
    }
    .scroll-mobile {
        overflow: auto !important;
    }
    .nav .user_profile .avatar img {
        width: 32px;
        height: 32px;
    }
    .nav .user_profile .avatar {
        border-radius: 100%;
        min-width: 32px;
        height: 32px;
        overflow: hidden;
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
    }
    /*END GAME*/
    .places-top {
        display: none !important;
    }
}
@-webkit-keyframes background {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 100%;
    }
}
@keyframes background {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 100%;
    }
}
.scrollbar {
    overflow: scroll;
    scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
    background-color: rgba(245, 245, 245, 0);
}
.scrollbar-primary {
    scrollbar-color: var(--colorTextMd)  rgba(255, 0, 0, 0);
}
.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--colorTextMd) ;
}
.scrollbar {
    overflow: scroll;
}
.livedrop {
    overflow-y:hidden;
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 163px;
    gap: 20px;
    grid-auto-flow: column;
}
.emoji-scroll-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.emoji-scroll-wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.emoji-scroll-wrapper::-webkit-scrollbar {
    background-color: rgba(245, 245, 245, 0);
}
.emoji-scroll-wrapper {
    scrollbar-color: var(--colorTextMd)  rgba(255, 0, 0, 0);
}
.emoji-scroll-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--colorTextMd) ;
}
.icon-rub .path1:before {
    content: "\e900";
    color: var(--textBtn);
}
.icon-rub .path2:before {
    content: "\e901";
    margin-left: -1em;
    color: var(--mainColor);
}
.icon-rub .path3:before {
    content: "\e902";
    margin-left: -1em;
    color: var(--mainColor);
}
.icon-rub .path4:before {
    content: "\e903";
    margin-left: -1em;
    color: var(--textBtn);
}
.icon-icondollar .path1:before {
    content: "\e904";
    color: var(--textBtn);
}
.icon-icondollar .path2:before {
    content: "\e905";
    margin-left: -1em;
    color: var(--mainColor);
}
.icon-icondollar .path3:before {
    content: "\e906";
    margin-left: -1em;
    color: var(--mainColor);
}
.icon-icondollar .path4:before {
    content: "\e907";
    margin-left: -1em;
    color: var(--textBtn);
}


.referral {
    height: 100%;
    margin-top: 50px;
}
.referral__actions {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;
}
.referral__actions-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    color: var(--mainText);
}

.referral__actions-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px
}
.referral-stat__items {
    display: -ms-grid;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.referral-stat__item {
    padding: 20px;
    background: var(--primaryBg);
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    gap: 10px;
}
.referral-stat__value {
    font-size: 28px;
    font-weight: 700;
    color: var(--mainText);
}
.referral-stat__desc {
    color: var(--colorTextLg);
    font-size: 14px;
}

/*@media (max-width: 1210px) {*/
/*    body {*/
/*        zoom: 0.55;*/
/*    }*/
/*}*/
/*@media (max-width: 1310px) {*/
/*    body {*/
/*        zoom: 0.65;*/
/*    }*/
/*}*/
@media (max-width: 1200px) {
    .bombContainer {
        flex-flow: row wrap !important;
    }

}
@media (min-width: 1400px) {
    body {
        zoom: 0.70;
    }
}
@media (min-width: 1500px) {
    body {
        zoom: 0.75;
    }
}
@media (min-width: 1600px) {
    body {
        zoom: 0.85;
    }
}
@media (min-width: 1800px) {
    body {
        zoom: 0.95;
    }
}
@media (min-width: 2000px) {
    body {
        zoom: 1;
    }
}
@media (min-width: 2200px) {
    body {
        zoom: 1;
    }
}
@media (min-width: 2400px) {
    body {
        zoom: 1.0;
    }
}
@media (min-width: 3400px) {
    body {
        zoom: 1.2;
    }
}
.example {
    display: -ms-grid;
    display: grid;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(black));
    background: -o-linear-gradient(top, white, black);
    background: linear-gradient(to bottom, white, black);
}
@-moz-document url-prefix() {
    .bombContainer {
        display: grid !important;
    }
}